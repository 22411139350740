import Clash from "../media/images/previouswork/Clash.png";
import Kingdom from "../media/images/previouswork/Kingdom.png";
import Buyers from "../media/images/previouswork/Buyers.png";
import Saloon from "../media/images/previouswork/Saloon.png";
import Spigot from "../media/images/previouswork/Spigot.png";
import Tracsis from "../media/images/previouswork/Tracsis.jpg";
import RITGCI from "../media/images/previouswork/RITGCI.jpg";

function TechBadge({ tech }) {
  return (
    <span className="tech-badge">
      {tech}
      <style jsx>{`
        .tech-badge {
          display: inline-block;
          background: var(--darker-primary);
          color: var(--text);
          padding: 5px 12px;
          border-radius: 20px;
          font-size: 14px;
          margin: 4px;
          font-weight: bold;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
          border: 1px solid rgba(var(--primary), 50%);
        }
      `}</style>
    </span>
  );
}

function TimelineItem({ name, image, year, desc, technologies, isLeft }) {
  return (
    <div className={`timeline-item ${isLeft ? "left" : "right"}`}>
      <div className="timeline-dot"></div>
      <div className="timeline-connector"></div>
      <div className="timeline-content">
        <div className="content-header">
          <img src={image} alt={name + " logo"} />
          <h3>{name}</h3>
          <div className="year">{year}</div>
        </div>
        <div className="desc">
          {desc}

          <div className="tech-section">
            <h4>Technologies</h4>
            <div className="tech-badges">
              {technologies.map((tech, index) => (
                <TechBadge key={index} tech={tech} />
              ))}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .timeline-item {
          position: relative;
          width: 47%; /* Increased from 40% */
          padding: 20px 0;
          margin-bottom: 30px;
          z-index: 1; /* Base z-index */
        }

        .timeline-item.left {
          align-self: flex-start;
          margin-right: auto;
          margin-left: 0%; /* Reduced from 5% */
          z-index: 2; /* Higher z-index for left items */
        }

        .timeline-item.right {
          align-self: flex-end;
          margin-left: auto;
          margin-right: 0%; /* Reduced from 5% */
        }

        .timeline-dot {
          position: absolute;
          top: 50%;
          width: 20px;
          height: 20px;
          background-color: var(--primary);
          border-radius: 50%;
          box-shadow: 0 0 0 4px rgba(var(--primary), 20%);
          z-index: 2;
        }

        .timeline-item.left .timeline-dot {
          right: -40px;
          transform: translateY(-50%);
        }

        .timeline-item.right .timeline-dot {
          left: -40px;
          transform: translateY(-50%);
        }

        .timeline-connector {
          position: absolute;
          top: 50%;
          height: 2px;
          background: var(--primary);
          width: 20px;
          z-index: 1;
        }

        .timeline-item.left .timeline-connector {
          right: -20px;
          transform: translateY(-50%);
        }

        .timeline-item.right .timeline-connector {
          left: -20px;
          transform: translateY(-50%);
        }

        .timeline-content {
          position: relative;
          padding: 25px;
          background: rgba(var(--primary), 6%);
          border: 3px solid var(--primary);
          border-radius: 15px;
          box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          backdrop-filter: blur(
            5px
          ); /* Add blur effect for overlapping sections */
        }

        .timeline-content:hover {
          transform: translateY(-5px);
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
        }

        .content-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(var(--primary), 30%);
        }

        .content-header img {
          width: 100px;
          height: auto;
          margin-bottom: 15px;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        .content-header h3 {
          margin: 0;
          font-family: Montserrat-Bold;
          font-size: 20px;
        }

        .year {
          font-family: Montserrat-Bold;
          color: var(--primary);
          margin: 8px 0;
          font-size: 16px;
          padding: 3px 10px;
          border-radius: 12px;
          background: rgba(var(--primary), 15%);
        }

        .desc {
          font-size: 16px;
          line-height: 1.6;
        }

        .tech-section {
          margin-top: 20px;
        }

        .tech-section h4 {
          font-size: 16px;
          margin-bottom: 10px;
          color: var(--primary);
        }

        .tech-badges {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
        }

        @media screen and (max-width: 1000px) {
          .timeline-item {
            width: 45%; /* Adjusted for smaller screens */
          }
        }

        @media screen and (max-width: 800px) {
          .timeline-item {
            width: 85%;
            margin-left: 60px;
            padding: 10px 0;
            z-index: 1; /* Reset z-index for mobile */
          }

          .timeline-item.left,
          .timeline-item.right {
            align-self: flex-start;
            margin-right: 0;
            margin-left: 60px;
          }

          .timeline-item.left .timeline-dot,
          .timeline-item.right .timeline-dot {
            left: -40px;
            right: auto;
          }

          .timeline-item.left .timeline-connector,
          .timeline-item.right .timeline-connector {
            left: -20px;
            right: auto;
          }
        }

        @media only screen and (max-width: 600px) {
          .timeline-item {
            width: 80%;
          }

          .content-header h3 {
            font-size: 18px;
          }

          .desc {
            font-size: 15px;
          }
        }

        @media only screen and (max-width: 450px) {
          .timeline-item {
            width: 75%;
            margin-left: 50px;
          }

          .tech-badge {
            font-size: 12px;
            padding: 4px 8px;
          }

          .content-header img {
            width: 80px;
          }

          .desc {
            font-size: 14px;
          }
        }
      `}</style>
    </div>
  );
}

function WorkExperience() {
  return (
    <>
      <div className="work-experience flex col" id="workexp">
        <div className="header">Work Experience</div>

        <div className="timeline-container">
          <div className="timeline-line"></div>

          <TimelineItem
            name="Tracsis"
            image={Tracsis}
            year="Jan 2024 - Present"
            desc={
              <p>
                As a Software Engineer Co-op, I independently rebuilt and
                refactored an existing Xamarin application to .NET MAUI using C#
                and XAML. Designed and implemented a customer-facing API using
                ASP.NET Core, delivering it from concept to production.
                Additionally, contributed to feature development and maintenance
                of multiple products utilizing C++, MFC, MSSQL, and Azure
                technologies both collaboratively and independently.
              </p>
            }
            technologies={[
              "C#",
              ".NET MAUI",
              "ASP.NET Core",
              "C++",
              "MSSQL",
              "Azure",
            ]}
            isLeft={true}
          />

          <TimelineItem
            name="RIT ESL Global Cybersecurity Institute"
            image={RITGCI}
            year="Feb 2023 - Aug 2023"
            desc={
              <p>
                Served as a Software Security Researcher, leading a team of
                frontend developers using Angular, Electron, TypeScript, and
                Figma to create secure applications. Made significant
                contributions to the backend infrastructure leveraging Java,
                Spring Framework, and Docker. Projects developed under a
                multi-million-dollar government contract were formally presented
                to the Department of Homeland Security and Cybersecurity and
                Infrastructure Security Agency.
              </p>
            }
            technologies={[
              "Angular",
              "TypeScript",
              "Electron",
              "Java",
              "Spring",
              "Docker",
            ]}
            isLeft={false}
          />

          <TimelineItem
            name="Skin Buyers"
            image={Buyers}
            year="2022"
            desc={
              <p>
                Developed a sophisticated Rust skin marketplace platform
                enabling users to trade digital assets securely for
                cryptocurrency. Implemented robust security protocols and
                intuitive user experience with real-time inventory tracking.
                Integrated directly with Steam API for seamless authentication
                and asset verification.
              </p>
            }
            technologies={[
              "JavaScript",
              "React",
              "Express",
              "MySQL",
              "Blockchain",
              "Steam API",
            ]}
            isLeft={true}
          />

          <TimelineItem
            name="Rust Kingdom"
            image={Kingdom}
            year="2021-2022"
            desc={
              <p>
                Architected and developed a comprehensive Rust gaming platform
                from concept to production, successfully attracting over 6,000
                registered users. Designed and implemented critical components
                including user authentication, secure payment processing,
                real-time game mechanics, and high-performance data management
                systems.
              </p>
            }
            technologies={[
              "JavaScript",
              "React",
              "Express",
              "MySQL",
              "Socket.io",
              "Redis",
            ]}
            isLeft={false}
          />

          <TimelineItem
            name="Rust Clash"
            image={Clash}
            year="2021"
            desc={
              <p>
                As Lead Developer, engineered comprehensive solutions for
                cryptocurrency transactions, Discord platform integration, and
                advanced security features across both frontend and backend
                systems. Prioritized creating a seamless user experience with
                responsive design and real-time data synchronization.
              </p>
            }
            technologies={[
              "JavaScript",
              "Vue",
              "Express",
              "MongoDB",
              "WebSockets",
              "Discord API",
            ]}
            isLeft={true}
          />

          <TimelineItem
            name="Rusty Saloon"
            image={Saloon}
            year="2021"
            desc={
              <p>
                Fulfilled the role of Lead Developer by architecting and
                implementing a comprehensive frontend redesign while maintaining
                backend system integrity. Enhanced user experience through
                responsive design principles, real-time functionality, and
                secure payment processing integration.
              </p>
            }
            technologies={[
              "JavaScript",
              "React",
              "Express",
              "MySQL",
              "WebSockets",
            ]}
            isLeft={false}
          />

          <TimelineItem
            name="Minecraft Development"
            image={Spigot}
            year="2015-2020"
            desc={
              <p>
                Engineered and published multiple Minecraft server plugins
                garnering over 4,000 downloads with an exceptional 4.5/5 average
                rating from more than 100 user reviews. Developed custom
                gameplay enhancements and administrative tools that improved
                server management capabilities and player experience.
              </p>
            }
            technologies={["Java", "Bukkit API", "Spigot"]}
            isLeft={true}
          />
        </div>
      </div>

      <style jsx>{`
        .work-experience {
          width: 100%;
          margin-top: 150px;
          align-items: center;
          font-size: 32px;
        }

        .header {
          font-family: Montserrat-Bold;
          margin-bottom: 70px;
          position: relative;
          padding-bottom: 10px;
        }

        .header:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 100px;
          height: 4px;
          background-color: var(--primary);
          border-radius: 2px;
        }

        .timeline-container {
          position: relative;
          width: 95%;
          max-width: 1200px;
          display: flex;
          flex-direction: column;
          padding: 20px 0;
          overflow: visible; /* Ensure overlap is visible */
        }

        .timeline-line {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          width: 4px;
          background-color: var(--primary);
          transform: translateX(-50%);
          border-radius: 2px;
        }

        @media only screen and (max-width: 800px) {
          .timeline-line {
            left: 20px;
            transform: none;
          }

          .timeline-container {
            width: 95%;
            margin-left: 5px;
          }
        }

        @media only screen and (max-width: 450px) {
          .header {
            font-size: 24px;
          }

          .work-experience {
            margin-top: 100px;
          }

          .header:after {
            width: 60px;
          }
        }
      `}</style>
    </>
  );
}

export default WorkExperience;
