import { useState } from "react";

function ContactMe() {
  const [showEmail, setShowEmail] = useState(false);

  // Email is split to avoid spam crawlers
  const emailParts = ["mail", "justinjantzi.com"];

  const handleRevealEmail = () => {
    setShowEmail(true);
  };

  return (
    <>
      <div className="contact-me flex col" id="contactme">
        <div className="header">Contact Me</div>
        <div className="contacts flex col">
          <div className="section flex">
            <div className="type">Email:</div>
            <div className="info">
              {showEmail ? (
                <a
                  href={`mailto:${emailParts[0]}@${emailParts[1]}`}
                  className="email-link"
                >
                  {emailParts[0]}@{emailParts[1]}
                </a>
              ) : (
                <button
                  onClick={handleRevealEmail}
                  className="reveal-email-btn"
                >
                  Click to reveal email
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .contact-me {
          width: 100%;
          margin-top: 150px;
          margin-left: 200px;
          align-items: flex-start;
          font-size: 32px;
        }

        .contacts {
          margin-top: 50px;
          gap: 25px;
        }

        .section {
          width: 100%;
          font-size: 24px;
          color: var(--alt-text);
        }

        .section > .type {
          margin-right: 150px;
          width: 100px;
          text-align: left;
        }

        .section > .info {
          margin-left: 50px;
        }

        .header {
          font-family: Montserrat-Bold;
        }

        .reveal-email-btn {
          background: rgba(var(--primary), 30%);
          border: none;
          color: var(--text);
          padding: 8px 16px;
          border-radius: 6px;
          cursor: pointer;
          font-size: 18px;
          transition: background 0.3s ease;
        }

        .reveal-email-btn:hover {
          background: rgba(var(--primary), 50%);
        }

        .email-link {
          color: var(--text);
          text-decoration: none;
          border-bottom: 1px dashed var(--primary);
        }

        .email-link:hover {
          border-bottom: 1px solid var(--primary);
        }

        @media only screen and (max-width: 800px) {
          .contact-me {
            font-size: 16px;
            margin-left: 0px;
            align-items: center;
          }

          .section > .type {
            margin-right: 0px;
            width: 60px;
          }

          .section {
            font-size: 12px;
          }

          .section > .info {
            margin-left: 0px;
          }

          .reveal-email-btn {
            font-size: 12px;
            padding: 6px 12px;
          }
        }
      `}</style>
    </>
  );
}

export default ContactMe;
