import RealMe from "../media/images/RealMe.png";

function AboutMe() {
  return (
    <>
      <div className="about-me flex col" id="aboutme">
        <div className="header">About Me</div>
        <div className="body flex">
          <div className="text">
            <div>
              I'm a passionate Software Engineer with over 10 years of industry
              experience and a strong academic foundation. I aim to continue
              applying my technical skills and academic knowledge to make
              impactful contributions to the field of software development.
              Throughout my career, I've thrived in diverse roles from hands-on
              development to leading collaborative teams on complex projects.
            </div>
            <br />
            <div>
              My technical expertise spans multiple programming languages
              including C#, C++, Java, JavaScript, Python, and HTML, among
              others. I enjoy tackling challenging problems and developing
              innovative solutions that meet user needs while driving efficiency
              and security. Whether it’s building robust APIs, refactoring
              legacy systems, or exploring emerging technologies, I am
              continually striving to learn and grow.
            </div>
            <br />
            <div>
              I'm always interested in connecting with fellow professionals and
              exploring opportunities for collaboration.
            </div>
          </div>

          <div className="image">
            <img className="me" src={RealMe} alt="Myself" />
            <div className="square" />
          </div>
        </div>
      </div>

      <style jsx>
        {`
          .about-me {
            width: 100%;
            margin-top: 150px;
            margin-left: 200px;
            align-items: flex-start;
            font-size: 32px;
          }

          .body > .text {
            font-size: 20px;
            color: var(--alt-text);
            text-align: left;
            width: 50%;
          }

          .image {
            width: 40%;
            position: relative;
            display: flex;
            justify-content: center;
          }

          .image > .me {
            border-radius: 12px;
            height: 350px;
            z-index: 200;
          }

          .square {
            border: 3px solid var(--primary);
            position: absolute;
            height: 350px;
            width: 350px;
            bottom: 0;
            z-index: 1;
            transform: translate(15%, 15%);
            border-radius: 12px;
          }

          .header {
            font-family: Montserrat-Bold;
          }

          @media only screen and (max-width: 1200px) {
            .about-me > .body > .text {
              font-size: 16px;
            }

            .image > .me {
              height: 200px;
            }

            .square {
              height: 180px;
              width: 180px;
            }
          }

          @media only screen and (max-width: 800px) {
            .about-me > .body {
              margin-top: 50px;
              flex-direction: column-reverse;
              width: 100%;
              align-items: center;
              gap: 50px;
            }

            .about-me {
              margin-left: 0px;
              align-items: center;
              margin-top: 50px;
            }
          }
        `}
      </style>
    </>
  );
}

export default AboutMe;
